<template>
	<div class="grid crud-demo">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="Neu" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
							<Button label="Löschen" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedServiceLevel || !selectedServiceLevel.length" />
						</div>
					</template>

					<template v-slot:end>
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="serviceLevel" v-model:selection="selectedServiceLevel" dataKey="ID" :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="{first} bis {last} von {totalRecords} ServiceLevel" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Service Levels</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Suchen..." />
                            </span>
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="ID" header="ID" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							{{slotProps.data.ID}}
						</template>
					</Column>
					<Column field="NAME" header="Bezeichnung" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Bezeichnung</span>
							{{slotProps.data.NAME}}
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editServiceLevel(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteServiceLevel(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="serviceLevelDialog" class:style="{width: '450px'}" header="Service Level Bezeichnung" :modal="true" class="p-fluid">
					<div class="field">
						<label for="NAME">Bezeichnung</label>
						<InputText id="name" v-model.trim="serviceLevelObj.NAME" required="true" autofocus :class="{'p-invalid': submitted && !serviceLevelObj.NAME}" />
						<small class="p-invalid" v-if="submitted && !serviceLevelObj.NAME">Name muss angegeben werden.</small>
					</div>
					<template #footer>
						<Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveServiceLevel" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteServiceLevelDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="serviceLevelObj"><b>{{serviceLevelObj.NAME}}</b> wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteServiceLevelDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteServiceLevel" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteServiceLevelsDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="serviceLevelObj">Ausgewählte ServiceLevel wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteServiceLevelsDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteSelectedServiceLevel" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import DataService from "@/service/DataService";

export default {
	data() {
		return {
      serviceLevel: null,
      serviceLevelDialog: false,
			deleteServiceLevelDialog: false,
			deleteServiceLevelsDialog: false,
      neu: false,
      serviceLevelObj: {},
			selectedServiceLevel: null,
			filters: {},
			submitted: false
		}
	},
  dataService: null,
	created() {
    this.dataService = new DataService();
		this.initFilters();
	},
	async mounted() {
    this.serviceLevel = await this.dataService.getServiceLevel();
  },
	methods: {
		openNew() {
			this.serviceLevelObj = {};
			this.submitted = false;
			this.serviceLevelDialog = true;
      this.neu = true;
		},
		hideDialog() {
			this.serviceLevelDialog = false;
			this.submitted = false;
		},
    async saveServiceLevel(){
      this.submitted = true;
      if(this.neu){
          await this.dataService.saveServiceLevel(this.serviceLevelObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'ServiceLevel erstellt', life: 3000});
          setTimeout(async()=> {this.serviceLevel = await this.dataService.getServiceLevel()}, 100);
          this.serviceLevelDialog = false;
          this.serviceLevelObj = {};

      } else {
          await this.dataService.updateServiceLevel(this.serviceLevelObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'ServiceLevel aktualisiert', life: 3000});
          setTimeout(async()=> {this.serviceLevel = await this.dataService.getServiceLevel()}, 100);
          this.serviceLevelDialog = false;
          this.serviceLevelObj = {};

      }

    },
    editServiceLevel(serviceLevel) {
      this.serviceLevelObj = {...serviceLevel};
      this.serviceLevelDialog = true;
      this.neu = false;
    },
    confirmDeleteServiceLevel(serviceLevel) {
      this.serviceLevelObj = serviceLevel;
      this.deleteServiceLevelDialog = true;
    },
    async deleteServiceLevel() {
      let data = [];
      data.push(this.serviceLevelObj);
      await this.dataService.deleteServiceLevel(data);

      this.deleteServiceLevelDialog = false;
      this.serviceLevelObj = {};
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Servicelevel gelöscht', life: 3000});
      setTimeout(async()=> {this.serviceLevel = await this.dataService.getServiceLevel()}, 100);

    },
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteServiceLevelsDialog = true;
		},
    async deleteSelectedServiceLevel() {
      this.deleteServiceLevelsDialog = false;
      await this.dataService.deleteServiceLevel(this.selectedServiceLevel);
      this.selectedServiceLevel = null;
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Servicelevel gelöscht', life: 3000});
      setTimeout(async()=> {this.serviceLevel = await this.dataService.getServiceLevel()}, 100);

    },
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/styles/badges.scss';
</style>
